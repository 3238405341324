import type { Portfolio, PortfolioFinancial } from '@endaoment-frontend/types';
import { arrToSorted } from '@endaoment-frontend/utils';

export const comparePortfoliosForSort = <MinimalPortfolio extends Pick<Portfolio, 'id' | 'type'>>(
  a: MinimalPortfolio,
  b: MinimalPortfolio,
): number => {
  // Sort by type
  if (a.type !== b.type) {
    // Always place PrivateWealth portfolios first
    if (a.type === 'PrivateWealth') return -1;
    if (b.type === 'PrivateWealth') return 1;
    return a.type.localeCompare(b.type);
  }
  // Sort by id
  return a.id.localeCompare(b.id);
};
export const sortPortfolios = <MinimalPortfolio extends Pick<Portfolio, 'id' | 'type'>>(
  portfolios: Array<MinimalPortfolio>,
): Array<MinimalPortfolio> => arrToSorted(portfolios, comparePortfoliosForSort);

export const getAllocatablePortfolios = (
  filterChainId?: number,
  portfolios?: Array<PortfolioFinancial>,
  allowExceedingCap = false,
): Array<PortfolioFinancial> => {
  if (!portfolios) return [];

  return portfolios.filter(
    p =>
      p.enabled &&
      // Filter out portfolios that have their cap maxed out
      (allowExceedingCap || !p.cap || p.totalInvestedInPortfolio < p.cap) &&
      // Filter out portfolios that are on a different chain than the currently selected one
      (!filterChainId || p.chainId === filterChainId),
  );
};

export const filterPortfolios = (
  search: string,
  filterChainId?: number,
  portfolios?: Array<PortfolioFinancial>,
  allowExceedingCap = false,
): Array<PortfolioFinancial> => {
  const allocatablePortfolios = getAllocatablePortfolios(filterChainId, portfolios, allowExceedingCap);
  const normalizedSearch = search.toLowerCase();

  if (!normalizedSearch) return allocatablePortfolios;
  return allocatablePortfolios.filter(p => {
    return (
      p.name.toLowerCase().includes(normalizedSearch) ||
      p.description.toLowerCase().includes(normalizedSearch) ||
      p.chainId.toString().includes(normalizedSearch)
    );
  });
};
